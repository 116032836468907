<template>
  <div class="buy--featured-agent">
    <div>
      <div class="buy--desc">
        {{ $t('featuredAgent.selectProvince') }}
      </div>
      <div class="area--select">
        <multiselect
          id="province"
          v-model="province"
          select-label=""
          deselect-label=""
          track-by="id"
          label="name"
          :searchable="true"
          :close-on-select="true"
          :show-labels="true"
          @input="getCities"
          :options="provinces"
          :placeholder="$t('featuredAgent.selectProvince')"
          class="area--multiselect"
        >
        </multiselect>
      </div>
    </div>
    <div v-if="province && province.id">
      <div class="buy--desc">
        {{ $t('featuredAgent.selectCity') }}
      </div>
      <div class="area--select">
        <multiselect
          id="city"
          v-model="city"
          select-label=""
          deselect-label=""
          track-by="id"
          label="name"
          @input="getAreas"
          :options="cities"
          :placeholder="$t('featuredAgent.selectCity')"
          class="area--multiselect"
        ></multiselect>
      </div>
    </div>
    <div v-if="city && city.id">
      <div class="buy--desc">
        {{ $t('featuredAgent.selectArea') }}
      </div>
      <div class="area--select">
        <multiselect
          id="area"
          v-model="area"
          select-label=""
          deselect-label=""
          track-by="id"
          label="name"
          :options="areas"
          class="area--multiselect"
          :placeholder="$t('featuredAgent.selectArea')"
        ></multiselect>
      </div>
    </div>
    <div class="slot--container" v-if="availabilities && availabilities.length > 0">
      <div class="buy--desc">{{ $t('featuredAgent.selectMonthYear') }}</div>
      <div class="row">
        <div
          class="col-6 col-sm-3 col-lg-2 px-2"
          :class="{
            selected: checkIfSelected(availability),
            disabled: checkIfDisabled(availability),
          }"
          v-for="availability in availabilities"
          :key="availability.uuid"
          @click="selectSlot(availability)"
        >
          <v-card class="slot--card d-flex justify-center align-center" style="position: relative">
            <div v-if="checkIfBought(availability)" class="already--bought">
              {{ $t('featuredAgent.alreadyBought') }}
            </div>
            <div
              :class="{
                bought: checkIfBought(availability),
              }"
            >
              <div class="slot--title">{{ availability.month_name }} {{ availability.year }}</div>
              <div class="slot--remaining">
                {{ `${$t('featuredAgent.remaining')}: ${availability.available_slot}` }}
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <div class="d-flex justify-center mt-4" v-if="availabilities && availabilities.length > 0">
      <button
        :disabled="loading || selectedAvailabilities.length === 0"
        type="submit"
        class="btn btn-primary main--btn"
        @click="tryBuyFeaturedAgent"
      >
        {{ $t('featuredAgent.btn.buy') }}
      </button>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';

require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'featured-agent-buy',
  mixins: [HelperMixin],
  components: { Multiselect },
  props: [],
  data: () => ({
    province: null,
    provinces: [],
    city: null,
    cities: [],
    areas: [],
  }),
  computed: {
    ...mapState({
      loading: (state) => state.featuredAgent.loading,
      availabilities: (state) => state.featuredAgent.availabilities,
    }),
    area: {
      get() {
        return this.$store.state.featuredAgent.area;
      },
      set(value) {
        this.$store.commit('featuredAgent/SET_AREA', value);
        this.getAreaAvailabilities();
      },
    },
    selectedAvailabilities: {
      get() {
        return this.$store.state.featuredAgent.selectedAvailabilities;
      },
      set(value) {
        this.$store.commit('featuredAgent/SET_SELECTED_AVAILABILITIES', value);
      },
    },
  },
  async mounted() {
    await this.$store.dispatch('featuredAgent/restoreInitialState');
    await this.getProvinces();
  },
  methods: {
    async getProvinces() {
      this.province = null;
      this.city = null;
      this.cities = [];
      this.area = null;
      this.areas = [];
      this.selectedAvailabilities = [];

      this.provinces = await this.$store.dispatch('masters/getProvinces');
    },
    async getCities(province) {
      this.city = null;
      this.area = null;
      this.areas = [];
      this.selectedAvailabilities = [];

      if (province && province.id) {
        this.cities = await this.$store.dispatch('masters/getCitiesByProvinceId', province.id);
      } else {
        this.cities = [];
      }
    },
    async getAreas(city) {
      this.area = null;
      this.selectedAvailabilities = [];

      if (city && city.id) {
        this.areas = await this.$store.dispatch('masters/getAreasByCityId', city.id);
      } else {
        this.areas = [];
      }
    },
    async getAreaAvailabilities() {
      this.selectedAvailabilities = [];
      await this.$store.dispatch('featuredAgent/getAreaAvailabilities');
    },
    checkIfSelected(availability) {
      return this.selectedAvailabilities.some(
        (item) => item.month === availability.month && item.year === availability.year,
      );
    },
    checkIfDisabled(availability) {
      return availability.already_bought || availability.available_slot <= 0;
    },
    checkIfBought(availability) {
      return availability.already_bought;
    },
    selectSlot(availability) {
      if (!this.checkIfDisabled(availability)) {
        const index = this.selectedAvailabilities.findIndex(
          (item) => item.month === availability.month && item.year === availability.year,
        );

        let newArray = this.selectedAvailabilities;
        if (index === -1) {
          newArray.push(availability);
        } else {
          newArray.splice(index, 1);
        }

        this.selectedAvailabilities = newArray;
      }
    },
    async tryBuyFeaturedAgent() {
      this.$modal.show('buy-featured-agent-modal', {
        submitAction: this.buyFeaturedAgent,
      });
    },
    async buyFeaturedAgent() {
      try {
        const response = await this.$store.dispatch('featuredAgent/buyFeaturedAgent');
        if (response) {
          await this.$swal(
            this.$t('featuredAgent.successT'),
            this.$t('featuredAgent.successD'),
            'success',
          );
          await this.$router.replace(`/featured-agent`);
        }
      } catch (e) {
        console.log('Failed to buy featured agent: ', e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';

.buy--featured-agent {
  .buy--desc {
    text-align: center;
    margin-bottom: 12px;
    font-size: $font-ml;
    @media #{$phones} {
      font-size: $font-md;
    }
  }

  .area--select {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    .area--multiselect {
      width: 500px;
      max-width: 500px !important;
    }
  }

  .already--bought {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-30deg);
    font-family: Poppins-SemiBold, sans-serif;
    font-size: $font-xl;
    color: $color-beliruma-1;
    text-align: center;
    z-index: 10;
  }

  .bought {
    opacity: 0.2;
  }

  .slot--container {
    .slot--card {
      height: 120px;
      cursor: pointer;
      padding: 8px 12px;
      border-radius: 16px !important;

      &:hover {
        box-shadow: $box-shadow-hover !important;
      }

      .slot--title {
        text-align: center;
        font-family: Poppins-SemiBold;
        font-size: $font-xl;
      }

      .slot--remaining {
        text-align: center;
      }
    }
    .selected {
      .slot--card {
        outline: solid 5px $color-beliruma-1 !important;
      }
    }
    .disabled {
      .slot--card {
        cursor: initial;
        background-color: $color-gray-1;

        &:hover {
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
        }
      }
    }
  }
}
</style>
